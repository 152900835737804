import React from "react"
import { graphql, Link } from "gatsby"
import { useTranslation } from "react-i18next"

const PimDataControlPage = ({ data, pageContext }) => {
  const { variants, groups, lines, areas, products } = data
  let languages =
    process.env.GATSBY_STORYBLOK_FOLDER_WHITELIST?.split(",") || null
  //console.log(t("generic.products.product_overview"))

  const directCopyToClipboard = (str) => {
    // Copy the text inside the text field
    navigator.clipboard.writeText(str)

    return false
  }

  return (
    <>
      <div className="my-4 mx-4">
        <div className="bg-red-50 text-red-600 p-10">
          <h2 className="font-bold text-2xl mb-2">PIM data control page</h2>
          <p className="mb-2">
            This page shows all contentserv product areas and lines per language
            that are currently used to build webpages.
          </p>
          <h3 className="font-bold mb-2">Usage tips</h3>
          <ul>
            <li>Click on 🌐 to open the page in a new tab.</li>
            <li>
              Click on any NUMBER, NAME or PATH to copy it to your clipboard.
            </li>
          </ul>
        </div>
        {languages.map((lang) => {
          return (
            <>
              {areas.edges.filter((area) => area.node.language === lang)
                .length > 0 && (
                <>
                  <h2 className="font-bold text-2xl bg-slate-500 p-3 text-white">
                    {lang.toUpperCase()} - product areas and lines
                  </h2>

                  <ul className="mb-2">
                    {areas.edges
                      .filter((area) => area.node.language === lang)
                      .map((area, index) => {
                        return (
                          <li className="pb-5" key={index}>
                            <div className="flex items-start gap-3 bg-slate-200 p-3">
                              <p className="block">
                                <a
                                  href={area.node.fullSlug}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="mr-3"
                                >
                                  🌐
                                </a>
                                <span
                                  className="w-20 hover:text-primary-400 cursor-pointer"
                                  title="click to copy ID to clipboard"
                                  onClick={(e) => {
                                    directCopyToClipboard(area.node.ID)
                                    e.preventDefault()
                                  }}
                                >
                                  {area.node.ID}
                                </span>
                                <span
                                  className="ml-6 hover:text-primary-400 cursor-pointer"
                                  title="click to copy NAME to clipboard"
                                  onClick={(e) => {
                                    directCopyToClipboard(area.node.name)
                                    e.preventDefault()
                                  }}
                                >
                                  {area.node.name}
                                </span>
                                <span
                                  className="ml-6 hover:text-primary-400 cursor-pointer"
                                  title="click to copy PATH to clipboard"
                                  onClick={(e) => {
                                    directCopyToClipboard(area.node.fullSlug)
                                    e.preventDefault()
                                  }}
                                >
                                  {area.node.fullSlug}
                                </span>
                              </p>
                            </div>
                            <ul className="block ml-20">
                              {lines.edges
                                .filter(
                                  (l) =>
                                    l.node.hierarchy.area.id === area.node.ID &&
                                    l.node.language === lang
                                )
                                .map((line, lindex) => {
                                  return (
                                    <li
                                      key={lindex}
                                      className="flex items-start gap-3"
                                    >
                                      <a
                                        href={line.node.fullSlug}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="mr-3"
                                      >
                                        🌐
                                      </a>
                                      <span
                                        className="w-20 hover:text-primary-400 cursor-pointer"
                                        title="click to copy ID to clipboard"
                                        onClick={(e) => {
                                          directCopyToClipboard(line.node.ID)
                                          e.preventDefault()
                                        }}
                                      >
                                        {line.node.ID}
                                      </span>
                                      <span
                                        className="ml-6 w-56 hover:text-primary-400 cursor-pointer"
                                        title="click to copy NAME to clipboard"
                                        onClick={(e) => {
                                          directCopyToClipboard(line.node.name)
                                          e.preventDefault()
                                        }}
                                      >
                                        {line.node.name}
                                      </span>
                                      <div
                                        className="hover:text-primary-400 cursor-pointer"
                                        title="click to copy PATH to clipboard"
                                        onClick={(e) => {
                                          directCopyToClipboard(
                                            line.node.fullSlug
                                          )
                                          e.preventDefault()
                                        }}
                                      >
                                        {line.node.fullSlug}
                                      </div>
                                    </li>
                                  )
                                })}
                            </ul>
                          </li>
                        )
                      })}
                  </ul>
                </>
              )}
            </>
          )
        })}
      </div>
      {/* <div className="my-4 mx-4">
        <h2 className="font-bold text-2xl">Product Groups:</h2>
        <ul className="mt-2">
          {groups.edges.map((group, index) => {
            return (
              <li key={index}>
                <Link
                  className="flex hover:text-primary"
                  to={group.node.fullSlug}
                >
                  <span className="w-20">{group.node.ID}</span>
                  <span className="w-20">{group.node.externalKey}</span>
                  <span className="ml-6">{group.node.name}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>

      <div className="my-4 mx-4">
        <h2 className="font-bold text-2xl">Produkte:</h2>
        <ul className="mt-2">
          {products.edges.map((products, index) => {
            return (
              <li key={index}>
                <Link
                  className="flex hover:text-primary"
                  to={products.node.fullSlug}
                >
                  <span className="w-20">{products.node.ID}</span>
                  <span className="w-20">{products.node.externalKey}</span>
                  <span className="ml-6">{products.node.name}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>

      <div className="my-4 mx-4">
        <h2 className="font-bold text-2xl">Varianten:</h2>
        <ul className="mt-2">
          {variants.edges.map((variant, index) => {
            return (
              <li key={index}>
                <Link
                  className="flex hover:text-primary"
                  to={variant.node.fullSlug}
                >
                  <span className="w-20">{variant.node.ID}</span>
                  <span className="w-20">{variant.node.externalKey}</span>
                  <span className="ml-6">{variant.node.name}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div> */}
    </>
  )
}

export const data = graphql`
  {
    variants: allContentServVariant {
      edges {
        node {
          name
          externalKey
          ID
          fullSlug
        }
      }
    }
    products: allContentServProduct {
      edges {
        node {
          name
          externalKey
          ID
          fullSlug
        }
      }
    }
    groups: allContentServProductGroup {
      edges {
        node {
          name
          ID
          externalKey
          fullSlug
        }
      }
    }
    lines: allContentServProductLine {
      edges {
        node {
          name
          ID
          language
          hierarchy {
            area {
              id
            }
          }
          externalKey
          fullSlug
        }
      }
    }
    areas: allContentServProductArea {
      edges {
        node {
          name
          ID
          language
          externalKey
          fullSlug
        }
      }
    }
  }
`

export default PimDataControlPage
